export const IMAGES_EXT = ['jpg', 'png', 'jpeg'];
export const FILES_EXT = ['xls', 'pdf'];
export const AVAILABLE_IMG_EXT = ['jpg', 'png', 'jpeg', 'xls', 'pdf'];
export const ALL_EXT = ['jpg', 'png', 'jpeg', 'svg', 'pdf', 'zip', 'flv', 'mp4', 'mov', 'avi', 'wmv', 'ppt'];
export const LANGUAGES = ['en', 'es', 'ja', 'pt', 'zh-CN', 'he', 'fr', 'pl'];
export const CATEGORIES = [
	'event_announcements',
	'distributor_materials',
	'prospecting_tools',
	'rank_recognition',
	'comp_plan_modules',
	'training_materials',
	'marketing_materials',
	'backoffice_tour_and_training',
];
export default {};
